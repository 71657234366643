<template>
  <div class="user-info">
    <el-tabs v-model="fullPath" @tab-click="onLink">
      <el-tab-pane v-for="item in exchangeOrderMenu" :key="item.path" :label="item.title" :name="item.path">
      </el-tab-pane>
    </el-tabs>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import { exchangeOrderMenu } from "@/db/objs"
export default {
  data() {
    return {
      exchangeOrderMenu,
      fullPath: this.$route.path
    };
  },
  methods: {
    onLink(e) {
      if (this.$route.path != this.fullPath) {
        this.$router.push({
          path: this.fullPath,
          query: this.$route.query
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.user-info {
  padding: .15rem;
}
</style>